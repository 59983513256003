<template>
    <div>About</div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: "About",

    created() {
        this.setDataLoaded(true)
    },

    methods: {
        ...mapActions('main', ['setDataLoaded']),
    }
};
</script>